import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #97978D;
  height: 90vh;
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    height: 100%;
  }
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    height: 100%;
  }
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: #FFFFFF;
  font-size: 48px;
  margin-bottom: 24px;
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    font-size: 2rem;
    text-align: center;
  }
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    flex-wrap: wrap;
    width: 100%;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const PricingCard = styled(motion.div)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 300px;
  text-decoration: none;
  border-radius: 4px;
  &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    &:hover {
      transform: none;
    }
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    width: 90vw;
    &:hover {
      transform: none;
    }
    &:nth-child(2) {
      margin: 24px 0;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
`;

export const PricingCardCost = styled.h4`
  font-size: 40px;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  padding: 1rem;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f7f7f7;
`;

const Footer = styled.footer`
  padding: 2.5rem 2rem 0.2rem 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 821px) and (max-width: 1200px) {
    justify-content: space-between;
    margin-left: 0;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    padding: 2rem 0;
  }
`;

const AddressBox = styled.div`
  display: inline-block;
  margin-left: 2rem;
  p {
    margin: 0;
    padding: 0;
    font-size: 0.95rem;
    font-weight: 200;
  }
  ul {
    margin: 0;
    list-style-type: none;
    font-weight: 200;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    margin-left: 0;
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 10px;
`;
const SubsAndPacks = () => {
  const date = new Date();
  return (
    <PricingSection>
      <PricingWrapper>
        <PricingHeading>Pricing</PricingHeading>
        <PricingContainer>
          <PricingCard>
            <PricingCardInfo>
              <PricingCardIcon></PricingCardIcon>
              <PricingCardPlan>On-Site Residential</PricingCardPlan>
              <PricingCardCost>$125.00</PricingCardCost>
              <PricingCardLength>per hour</PricingCardLength>
              <PricingCardFeatures>
                <PricingCardFeature>By appointment only</PricingCardFeature>
              </PricingCardFeatures>
            </PricingCardInfo>
          </PricingCard>
          <PricingCard>
            <PricingCardInfo>
              <PricingCardIcon></PricingCardIcon>
              <PricingCardPlan>On-site Business</PricingCardPlan>
              <PricingCardCost>$150.00</PricingCardCost>
              <PricingCardLength>per hour</PricingCardLength>
              <PricingCardFeatures>
                <PricingCardFeature>By appointment only</PricingCardFeature>
              </PricingCardFeatures>
            </PricingCardInfo>
          </PricingCard>
          <PricingCard>
            <PricingCardInfo>
              <PricingCardIcon></PricingCardIcon>
              <PricingCardPlan>Drop-off Services</PricingCardPlan>
              <PricingCardCost>$85.00</PricingCardCost>
              <PricingCardLength>per hour</PricingCardLength>
              <PricingCardFeatures>
                <PricingCardFeature>Drop your machine off in our local office</PricingCardFeature>
              </PricingCardFeatures>
            </PricingCardInfo>
          </PricingCard>
        </PricingContainer>
        <Footer>
        <h2>
          <NavLink id="footerlogo" to="/">
            Rosebytes
          </NavLink>
        </h2>
        <AddressBox>
          <p>{date.getFullYear()} Rosebytes, LLC</p>
        </AddressBox>
      </Footer>
      </PricingWrapper>
    </PricingSection>
  );
};

export default SubsAndPacks;
