import React from "react";
import styled from "styled-components";
import { Layout } from "../styles";
import Toggle from "./Toggle";
import { NavLink } from "react-router-dom";
// Animation
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "../hooks/useScroll";

const FAQ = styled(Layout)`
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  h4 {
    font-size: 1.7em;
    letter-spacing: 1.5px;
    cursor: pointer;
  }
  .faq-line {
    background-color: #ccc;
    height: 0.2rem;
    margin: 2rem 0;
    width: 100%;
  }
  .question {
    padding: 0.5rem 0rem;
    svg {
      cursor: pointer;
    }
  }
  .answer {
    padding: 2rem 0;
    p {
      padding: 1rem 0;
      font-size: 1.2rem;
      letter-spacing: 1.5px;
    }
    ul {
      list-style-type: circle;
      li {
        margin-left: 2rem;
        color: #FFE347;
        font-size: 1.1rem;
      }
    }
  }
  .header-inline {
    display: flex;
    justify-content: space-between;
  }
  line {
    stroke-width: 10px;
    stroke-linecap: round;
    fill: transparent;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 3rem;
    }
    .question, .answer {
      width: 100%;
      font-size: 0.7rem;
    }
  }
`;

const Footer = styled.footer`
  padding: 2.5rem 2rem 0.2rem 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 821px) and (max-width: 1200px) {
    justify-content: space-between;
    margin-left: 0;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    padding: 2rem 0;
  }
`;

const AddressBox = styled.div`
  display: inline-block;
  margin-left: 2rem;
  p {
    margin: 0;
    padding: 0;
    font-size: 0.95rem;
    font-weight: 200;
  }
  ul {
    margin: 0;
    list-style-type: none;
    font-weight: 200;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    margin-left: 0;
    h2 {
      font-size: 1.5rem;
    }
  }
`;

const FaqSection = () => {
  const date = new Date();
  const [element] = useScroll();

  return (
    <FAQ initial="hidden" ref={element} className="faq">
      <h2>
        <span>FAQ</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="How do I do business with you?">
          <div className="answer">
            <p>Let's have a discussion about your needs.</p>
            <p>
              Emailing us is the most efficient and streamlined way to communicate your technical needs. By providing us with all the necessary information upfront, we can quickly and effectively put together a comprehensive plan of action to address your requirements. Rest assured, our team of experts will work diligently to ensure that all of your needs are met and that the work is performed to the highest standards. Let us help you by emailing us today.
              <NavLink
                className="styled-link"
                to="/contact"
                style={{ width: "fit-content", color: "#FFE347" }}
              >
                Contact Us
              </NavLink>
            </p>
          </div>
        </Toggle>
        <Toggle title="Why work with us?">
          <div className="answer">
            <p>
              <b>
                <em>EXPERIENCE</em>
              </b>
            </p>
            <p>
              "With over a decade of experience in the technical industry and a proven track record of success at companies like Google and Uber, I am the ideal candidate to handle all of your technical needs. As the sole employee of this company, I bring a wealth of knowledge and expertise to the table, specifically in the areas of support, DevOps, site reliability engineering, and infrastructure. My skillset includes Linux systems administration, scaling, multi-region deployments, high-availability, containerization, and configuration management. I am confident that I can provide you with the level of service and support that you require, and exceed your expectations."<br/>
               - Joseph Ziegler
            </p>
          </div>
        </Toggle>
        <Toggle title="Different Payment Methods">
          <div className="answer">
            <p>We offer several payment methods.</p>
            <p>
              We accept primarily{" "}
              <b>
                <em>VISA, MasterCard, Check, and Cash.</em>
              </b>{" "}
                We use square as our payment processor for debit and credit cards; however, cash and check are also accepted. Please make check out to Rosebytes, LLC.
              <NavLink
                className="styled-link"
                to="/subspack"
                style={{ width: "fit-content", color: "#FFE347" }}
              >
                Pricing
              </NavLink>
            </p>
          </div>
        </Toggle>
        <Toggle title="What services do you offer?">
          <div className="answer">
            <p>
              We offer a variety of services:
              <ul>
                <li>Computer diagnostics & troubleshooting</li>
                <li>Hardware upgrades</li>
                <li>Computer building & part replacement</li>
                <li>Linux server maintenance and configuration</li>
                <li>CI/CD Pipeline implementation</li>
                <li>Application containerization</li>
                <li>Computer Training</li>
                <li>Server / website hosting</li>
                <li>Server installation</li>
                <li>Domain management</li>
                <li>Email implementation (Google Workspaces)</li>
                <li>Configuration management (Puppet/Ansible)</li>
                <li>Infrastructure as Code (IaC/Terraform)</li>
                <li>Infrastructure design</li>
              </ul>
            </p>
            <p>
              Technology is vast and constantly evolving, which is why we continuously strive to expand our range of services to meet our clients' ever-changing needs. While we have listed a variety of services above, it is by no means the only services we provide. If you have specific requirements that are not mentioned, please do not hesitate to reach out to us. We pride ourselves on being able to provide customized solutions and are always willing to explore new possibilities.
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
      <Footer>
        <h2>
          <NavLink id="footerlogo" to="/">
            Rosebytes
          </NavLink>
        </h2>
        <AddressBox>
          <p>{date.getFullYear()} Rosebytes, LLC</p>
        </AddressBox>
      </Footer>
    </FAQ>
  );
};

export default FaqSection;
